import {
  styled,
  Theme,
  CSSObject
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { mainMenuItems, additionalMenuItems } from "../MenuItems/MenuItems";
import { IDesktopMenu } from "../../interfaces";
import { MenuList } from "../MenuItems/MenuList";
import { useSelector } from "react-redux";
import { userDataSelector } from '../../../redux/selectors/userDataSelector';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: "82px",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export const DesktopMenu = ({
  activePath,
  handleMenu,
  open,
  navigate
}: IDesktopMenu) => {
  const userData = useSelector(userDataSelector);

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={() => handleMenu(!open)}
      variant="permanent"
    >
      <MenuList
        activePath={activePath}
        list={mainMenuItems.filter( item => 
          (item.id !== 'rating' || (item.id === 'rating' && userData?.source === 'guesty')) &&
          (item.id !== 'listings' || (item.id === 'listings' && userData?.source === 'guesty'))
        )}        
        navigate={navigate}
      />
      {/* <MenuList
        activePath={activePath}
        list={additionalMenuItems.filter( item => item.id != 'listings' || item.id == 'listings' && userData?.source == 'guesty'  )}
        navigate={navigate}
      /> */}
    </Drawer>
  );
};
